import dayjs from 'dayjs';
import { ICombo } from 'app/shared/model/combo.model';
import { PromotionStatus } from 'app/shared/model/enumerations/promotion-status.model';
import { IPaymentOptions } from './payment-options.model';

export interface ISchoolPromotion {
  id?: number;
  name?: string | null;
  status?: PromotionStatus | null;
  contrato?: string | null;
  finishDate?: string | null;
  deliveryDate?: string | null;
  expiration01?: string | null;
  expiration02?: string | null;
  expiration03?: string | null;
  expiration04?: string | null;
  expiration05?: string | null;
  expiration06?: string | null;
  expiration07?: string | null;
  expiration08?: string | null;
  expiration09?: string | null;
  expiration10?: string | null;
  expiration11?: string | null;
  expiration12?: string | null;
  designUrl?: string | null;
  validatedDesign?: boolean | null;
  talles?: string | null;
  coef01?: number | null;
  coef02?: number | null;
  coef03?: number | null;
  startDate?: Date | null;
  interest?: number | null;
  paymentGraceTime?: number | null;
  combos?: ICombo[] | null;
  paymentOptions?: IPaymentOptions[] | null;
}

export const defaultValue: Readonly<ISchoolPromotion> = {
  validatedDesign: false,
};
